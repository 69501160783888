
import owlCarousel from "./owl.carousel.js";
import { createPopper } from '@popperjs/core';
import '../scss/style-v1.scss';
import {throttle} from "lodash"
import Vue from "vue/dist/vue.js";
import moment from 'moment';
import datepicker from '../assets/datepicker.min.js';




$(document).ready(function(){

//main
  $('.owl-carousel.main').owlCarousel({
    loop:true,
    margin:0,
    nav:true,
    navElement:'div',
    navText:["<button class='btn slider-prev'><span class='slider-prev--text'>Junior Suite</span><img src='./img/slider-arrow-left.svg'></button>","<button class='btn slider-next'><span class='slider-next--text'>Studio</span><img src='./img/slider-arrow-right.svg'></button>"],
    items:1
})

//rooms
$('.owl-carousel.rooms').owlCarousel({
  loop:true,
  margin:0,
  nav:true,
  navElement:'div',
  navText:["<button class='btn slider-prev'><span class='slider-prev--text'>Junior Suite</span><img src='./img/slider-arrow-left.svg'></button>","<button class='btn slider-next'><span class='slider-next--text'>Studio</span><img src='./img/slider-arrow-right.svg'></button>"],
  items:1,
  
})

//detail-rooms
$('.owl-carousel.detail-rooms')
.on('initialize.owl.carousel',function(e){
  
  const currentSlide = $(e.target).parent('.slider-container').find('.current-slide');
  const allSlides = $(e.target).parent('.slider-container').find('.all-slids');
  currentSlide.text('01');
  allSlides.text($(e.target).find('.slider-item').length > 0 && $(e.target).find('.slider-item').length < 10 ? `0${$(e.target).find('.slider-item').length}` : $(e.target).find('.slider-item').length);
})
.owlCarousel({
  loop:true,
  nav:true,
  navElement:'div',
  navText:["<button class='btn slider-prev'><img src='./img/arrow-details-room-left.svg'></button>","<button class='btn slider-next'><img src='./img/arrow-details-room-right.svg'></button>"],
  items:1,
  responsive:{
    0:{
      stagePadding: 12,
      margin:2,
    },
    768:{
      margin:0,
    }
  }
}).on('changed.owl.carousel',function(e){
  const currentSlide = $(e.target).parent('.slider-container').find('.current-slide');
  const allSlides = $(e.target).parent('.slider-container').find('.all-slids');
  currentSlide.text((e.page.index + 1 < 10 &&  e.page.index + 1 > 0) ? `0${e.page.index + 1}`: e.page.index + 1 );
  allSlides.text((e.page.count < 10 &&  e.page.count > 0) ? `0${e.page.count}` : e.page.count);
})

$('.owl-carousel.history')
.on('initialize.owl.carousel',function(e){

  const currentSlide = $(e.target).parent('.slider-container').find('.current-slide');
  const allSlides = $(e.target).parent('.slider-container').find('.all-slids');
  currentSlide.text('01');
  allSlides.text($(e.target).find('.slider-item').length > 0 && $(e.target).find('.slider-item').length < 10 ? `0${$(e.target).find('.slider-item').length}` : $(e.target).find('.slider-item').length);
})
.owlCarousel({
  loop:true,
  nav:true,
  navElement:'div',
  navText:["<button class='btn slider-prev'><img src='./img/arrow-details-room-left.svg'></button>","<button class='btn slider-next'><img src='./img/arrow-details-room-right.svg'></button>"],
  items:1,
  responsive:{
    0:{
      stagePadding: 12,
      margin:2,
    },
    768:{
      margin:0,
    }
  }
}).on('changed.owl.carousel',function(e){
  const currentSlide = $(e.target).parent('.slider-container').find('.current-slide');
  const allSlides = $(e.target).parent('.slider-container').find('.all-slids');
  currentSlide.text((e.page.index + 1 < 10 &&  e.page.index + 1 > 0) ? `0${e.page.index + 1}`: e.page.index + 1 );
  allSlides.text((e.page.count < 10 &&  e.page.count > 0) ? `0${e.page.count}` : e.page.count);
})


//drawer-nav
$(".toggle-drawer").click(function(e){
  let _this = $(this);
  $(".drawer").toggleClass('active');
})
//dropdown
$(".dropDown-toggle").click(function(e){
  e.preventDefault();
  let _this = $(this);
  if(_this.parent('.dropDown').hasClass('active')){
    _this.removeClass('active');
  }
  else{
    _this.addClass('active');
  }
    _this.parent('.dropDown').toggleClass('active')
    
});

function removeActive(_this){
  _this.forEach(item=>{
    item.classList.remove('active');
  })
};

(function mobileSliderWithThumbs(){
  
  const sliders = document.querySelectorAll('.js-slider');

  sliders.forEach(slider=>{

    const slidersContainer = slider.querySelector('.slider');
  
    const sliderNav = slider.querySelectorAll('.thumbs-link');
    
    const sliderItems = slidersContainer.querySelectorAll('.slider-item');
    
    let currentPosition = 0;
    
    function spySlider(e){
      if(sliderNav.length){
        currentPosition = parseInt(this.scrollLeft+50);
      
        sliderItems.forEach((item,index)=>{
          if(index == 0){
            if(currentPosition <= sliderItems[index+1].offsetLeft){
              removeActive(sliderNav);
              sliderNav[index].classList.add('active');
            }
          }
          else if(index != 0 && index != sliderItems.length - 1){
              if(currentPosition >= item.offsetLeft && currentPosition <= sliderItems[index+1].offsetLeft){
                removeActive(sliderNav);
                sliderNav[index].classList.add('active');
              }
          }
          else if(index == sliderItems.length - 1){
            console.log(currentPosition,item.offsetLeft);
            if(currentPosition >= item.offsetLeft){
              removeActive(sliderNav);
                sliderNav[index].classList.add('active');
            }
          }
          
        })
      }
      return null
      
    }
    
    slidersContainer.addEventListener("scroll", throttle(spySlider,200));
    
    sliderNav.forEach((item,index)=>{
      item.onclick = (e) =>{
        e.preventDefault();
        slidersContainer.scrollTo({
              left:sliderItems[index].offsetLeft,
              behavior:"smooth"
            })
      }
    })
  })})()


//slidDown 
if($('.btn-arrow')){
  $('.btn-arrow').click(function(e){
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  })
}


//form counter


const counterTarget = document.querySelector('[name="guests"]');
const dateFromTarget = document.querySelector('[name="date_from"]');
const dateToTarget = document.querySelector('[name="date_to"]');

$.fn.datepicker.language['en'] = {
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'Today',
  clear: 'Clear',
  dateFormat: 'mm.dd.yyyy',
  timeFormat: 'hh:ii aa',
  firstDay: 0
};
const currentDate = new Date();
const calendarb = Vue.component('calendarb', {
  data:function(){
    return ({
      pickerFrom: $(dateFromTarget),
      pickerTo: $(dateToTarget),
      pickerToCalendarb: null,
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
      monthFrom:currentDate.getMonth(),
      yearFrom:currentDate.getFullYear(),
      view:'days',
      viewFrom:'days'
    })
  },
  props:['setdate','datefrom','dateto'],
  template: document.getElementById('templateDatePicker'),
  // watch:{
  //   datefrom:function(){
  //     $(dateToTarget).data('datepicker').update('minDate',this.datefrom);
  //   }
  // },
  mounted: function(){
      const _this = this;
      
     
      _this.pickerTo.datepicker({
        language:'en',
        classes:'datepickerTo',
        moveToOtherMonthsOnSelect:false,
        minDate:_this.datefrom,
        prevHtml:'<div class="prev-btn"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M6 11L1 6L6 1" stroke="#CEC9B3"/></svg><span class="prev-text">prev</span></div>',
        nextHtml:'<div class="next-btn"><span class="next-text">next</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M1 11L6 6L1 1" stroke="#CEC9B3"/></svg></div>',
        
        onShow:function(dp,anim){       
          _this.pickerToCalendarb = dp.$datepicker;
          _this.hilightRange( dp.$datepicker,_this.dateto);
          if(_this.view == 'days'){
          
            _this.getMonth(_this.dateto,'.datepickerTo');
           }
           else if(_this.view == 'months'){
            _this.getYear(_this.dateto,'.datepickerTo');
           }
           else {
            _this.getDecade(_this.dateto,'.datepickerTo');
           }
          
        },
        onChangeMonth:function(month,year){
          
          const date = new Date(_this.year,month,1);
          _this.month = month;
          _this.getMonth(date,'.datepickerTo');
          setTimeout(()=>{
            _this.hilightRange($('.datepickerTo'),_this.dateto);
          });
        },
        onChangeYear:function(year){
        const date = new Date(year,_this.month,1);
        _this.year = year;
        if(_this.view == 'days'){
          _this.getMonth(date,'.datepickerTo');
         }
         else if(_this.view == 'months'){
          _this.getYear(date,'.datepickerTo');
         }
         else {
          _this.getDecade(date,'.datepickerTo');
         }  
        },
        onChangeView:function(view){
         _this.view = view;
         const date = new Date(_this.year,_this.month,1);
         if(view == 'days'){
          _this.getMonth(date,'.datepickerTo');
         }
         else if(_this.view == 'months'){
          _this.getYear(date,'.datepickerTo');
         }
         else {
          _this.getDecade(date,'.datepickerTo');
         }
         
        
        },
        onChangeDecade:function(decade){
          const date = new Date(_this.year,_this.month,1);
          
          if(_this.view == 'days'){
            _this.getMonth(date,'.datepickerTo');
           }
           else if(_this.view == 'months'){
            _this.getYear(date,'.datepickerTo');
           }
           else {
            _this.getDecade(date,'.datepickerTo');
           } 
        },
        onSelect:function(formated,date,inst){
          _this.setdate('date_to',date); 
          
          setTimeout(()=>{
            _this.hilightRange($('.datepickerTo'),_this.dateto);
          });  
        }
      });
      _this.pickerFrom.datepicker({
        minDate:new Date(),
        language:'en',
        classes:'datepickerFrom',
        moveToOtherMonthsOnSelect:false,
        maxDate:_this.dateto,
        prevHtml:'<div class="prev-btn"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M6 11L1 6L6 1" stroke="#CEC9B3"/></svg><span class="prev-text">prev</span></div>',
        nextHtml:'<div class="next-btn"><span class="next-text">next</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M1 11L6 6L1 1" stroke="#CEC9B3"/></svg></div>',
        onShow:function(){
          const date = _this.datefrom.length > 0 ? this.datefrom : new Date();
        
          if(_this.viewFrom == 'days'){
            _this.getMonth(date,'.datepickerFrom');
           }
           else if(_this.viewFrom == 'months'){
            _this.getYear(date,'.datepickerFrom');
           }
           else {
            _this.getDecade(date,'.datepickerFrom');
           }
        },
       onChangeMonth:function(month){
         const date = new Date(_this.yearFrom,month,1);
         _this.monthFrom = month;
         if(_this.viewFrom == 'days'){
          _this.getMonth(date,'.datepickerFrom');
         }
         else if(_this.viewFrom == 'months'){
          _this.getYear(date,'.datepickerFrom');
         }
         else {
          _this.getDecade(date,'.datepickerFrom');
         }
       
       },
       onChangeYear:function(year){
         const date = new Date(year,_this.monthFrom,1);
         _this.yearFrom = year;
         if(_this.viewFrom == 'days'){
          _this.getMonth(date,'.datepickerFrom');
         }
         else if(_this.viewFrom == 'months'){
          _this.getYear(date,'.datepickerFrom');
         }
         else {
          _this.getDecade(date,'.datepickerFrom');
         }
       },
       onChangeDecade:function(decade){
        const date = new Date(_this.yearFrom,_this.monthFrom,1);
        
        if(_this.viewFrom == 'days'){
          _this.getMonth(date,'.datepickerFrom');
         }
         else if(_this.viewFrom == 'months'){
          _this.getYear(date,'.datepickerFrom');
         }
         else {
          _this.getDecade(date,'.datepickerFrom');
         } 
      },
      onChangeView:function(view){
        _this.viewFrom = view;
        const date = new Date(_this.year,_this.month,1);
        if(view == 'days'){
         _this.getMonth(date,'.datepickerFrom');
        }
        else if(_this.view == 'months'){
         _this.getYear(date,'.datepickerFrom');
        }
        else {
         _this.getDecade(date,'.datepickerFrom');
        }
        
       
       },
        onSelect:function(formated,date,inst){
          _this.setdate('date_from',date);
          const currentDate = moment(date).add('days',1);
          _this.pickerTo.data('datepicker').selectDate(currentDate._d);
          $(dateToTarget).data('datepicker').update('minDate',date);
        },
      });
     
      dateFromTarget.addEventListener('click',function(e){
        $('.datepickerFrom').addClass('active');
      })
      dateToTarget.addEventListener('click',function(e){
        $('.datepickerTo').addClass('active');
      })
  },
  methods:{
    hilightRange:function(dp,initDate=null){
      const AllCell = dp.find('.datepicker--cell-day:not(.-disabled-)');
        let indexSelected = null;
         AllCell.each((index,item)=>{
           if(initDate){
            const newDate = new Date(item.dataset.year,item.dataset.month,item.dataset.date);
         
            if(moment(initDate).isAfter(newDate)){
              indexSelected = AllCell.length - 1;
            }
            else{
              if($(item).hasClass('-selected-')){
                indexSelected = index;
               }
            }
            
           }
            
            else{
              if($(item).hasClass('-selected-')){
                indexSelected = index;
               }
            }    
         });
         AllCell.each((index,item)=>{
              const newDate = new Date(item.dataset.year,item.dataset.month,item.dataset.date);
                if(index == 0 && moment(initDate).isAfter(newDate)){
                item.classList.add('-hilight-');
                item.classList.add('first');
               }
               else if(index> 0 && index<indexSelected){
                 item.classList.add('-hilight-');
               }
               else if(index == indexSelected){
                item.classList.add('-hilight-');
                if(JSON.stringify(newDate) != JSON.stringify(this.datefrom)){
                  item.classList.add('last');
                }
                else{
                  item.classList.add('select');
                }
               
               } 
         })
         const AllHilight =  document.querySelectorAll('.-hilight-');
          AllHilight.forEach((item,index)=>{
            if(typeof AllHilight[index+7] != "undefined"){
              item.style.borderBottom = "0";
              AllHilight[index+7].style.borderTop = "0";
            }
         });
         dp.find('.datepicker--cell-day').each((index,item)=>{
           if(index % 7 == 0 && $(item).hasClass('-hilight-')){
             item.style.borderLeft = "1px solid #68665b";
           }
         })
         dp.find('.datepicker--cell-day.-weekend-').each((index,item)=>{
          if($(item).hasClass('-hilight-') && index % 2 == 1){
            item.style.borderRight = "1px solid #68665b";
          }})
        
    },
    getMonth:function(date,node){
      
      const currentDate = date;
      const prevDate = moment(date).add('month',-1).format('MMM');
      const nextDate = moment(date).add('month',1).format('MMM');

      $(node).find('.next-text').text(nextDate);
      $(node).find('.prev-text').text(prevDate);

    },
    getYear:function(date,node){
      
      const currentDate = date;
      const prevDate = moment(date).add('year',-1).format('YYYY');
      const nextDate = moment(date).add('year',1).format('YYYY');

      $(node).find('.next-text').text(nextDate);
      $(node).find('.prev-text').text(prevDate);

    },
    getDecade:function(date,node){
      
      const currentDate = date;
      const prevDate = moment(date).add('year',-10).format('YYYY');
      const nextDate = moment(date).add('year',10).format('YYYY');

      $(node).find('.next-text').text(nextDate);
      $(node).find('.prev-text').text(prevDate);

    }
  
  }
});

  const formApp = new Vue({
    el:"#formApp",
    template:document.querySelector('#templateFormApp'),
    data:{
      children:0,
      adults:0,
      date_from: '',
      date_to: '',
      showPopper: false,
      showCounter: false,
      showDateFrom: false
    },
    mounted:function(){
     
      counterTarget.onclick = () => {
          this.showPopper = !this.showPopper;
          this.showCounter = !this.showCounter;
          this.handleShowPopper(counterTarget);
      }
      dateFromTarget.onclick = () =>{
        this.showDateFrom = true;
      }     
    },
    watch:{
      children:function(e){
        counterTarget.value = this.adults + this.children;
      },
      adults:function(e){
        counterTarget.value = this.adults + this.children;
      },
      date_from:function(e){

        const value = dateFromTarget.closest('.form-control').querySelector('.calendarb-value');
        const placeholder = dateFromTarget.closest('.form-control').querySelector('.calendarb-placeholder');
        
        value.classList.remove('disabled');
        placeholder.classList.add('disabled');
        dateToTarget.disabled = false;

        value.innerText = moment(this.date_from).format('DD.MM.YYYY');
        dateFromTarget.value = moment(this.date_from).format('DD.MM.YYYY');

      },
      date_to:function(e){
        const value = dateToTarget.closest('.form-control').querySelector('.calendarb-value');
        const placeholder = dateToTarget.closest('.form-control').querySelector('.calendarb-placeholder');
        
        value.classList.remove('disabled');
        placeholder.classList.add('disabled');
        value.innerText = moment(this.date_to).format('DD.MM.YYYY');
        dateToTarget.value = moment(this.date_to).format('DD.MM.YYYY');
        dateToTarget.closest('.form.main').querySelectorAll('.btn').forEach(item=>item.disabled = false);
      
      }
    },
    methods:{
      handleShowPopper: function(target){
        if(this.showPopper){
          if(window.innerWidth >= 768){
            setTimeout(()=>{
              createPopper(target,document.querySelector('.popper'));
              document.querySelector('.popper').classList.add('active');
            },100)
          }
          else{
            setTimeout(()=>{
              document.querySelector('.popper').classList.add('active');
            },100)
          }
        }
      },
      increment:function(key){
          if(this[key] < 10){
            this[key]++;
          }
      },
      dicrement:function(key){
          if(this[key] > 0){
            this[key]--;
          } 
      },
      closePopup:function(){
        this.showPopper = false;
        this.showCounter = false;
      },
      setDate:function(key,data){
        this[key] = data;
        
      }
     
    }


  })
  const counter = Vue.component('counter', {
    data: function () {
      return {
        count: 0
      }
    },
    props:[
      'close',
      'children',
      'adults',
      'increment',
      'dicrement',  
    ],
    template: document.getElementById('templateCounter'),
  });

    
});



